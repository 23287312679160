
// Modal
function modal() {
	
	let scrollPos = 0;

	// // active modal
	function openModal(id) {

		const targetModal = document.querySelector(`[data-modal-id=${id}]`);

		document.documentElement.style.scrollBehavior = 'auto';

		// if modal has video
		const targetVideo = document.querySelector(`[data-modal-id=${id}] video`);
		const targetSource = document.querySelector(`[data-modal-id=${id}] source`);
		if ( targetVideo && targetSource ) {
			targetSource.src = targetSource.getAttribute('data-src');
			targetVideo.load();
			targetVideo.play();
		}

		// active modal
		targetModal.classList.add('is-active');
		document.body.classList.add(`modal-open`);

		// if modal has gallery
		const targetGallery = document.querySelector(`[data-modal-id=${id}] .c-carousel`);
		const flkty = new Flickity(targetGallery);
		flkty.resize();

		// fix body
		scrollPos = window.scrollY;
		document.body.style.cssText += `overflow-y:hidden;touch-action:none;position:fixed;top:${-scrollPos}px`;
	}

	// // deactivate modal
	function closeModal(id) {
			const target = document.querySelector(`.c-modal.is-active`);

			const video = document.querySelector(`.c-modal.is-active video`);
			if ( video ) video.pause();

			document.body.classList.add('modal-going');

			setTimeout(()=>{
				target.classList.remove('is-active');
				document.body.classList.remove('modal-going');
				document.body.classList.remove('modal-open');
				document.body.style.overflowY = '';
				document.body.style.touchAction = '';
				document.body.style.position = '';
				document.body.style.top = '';
				window.scrollTo(0, scrollPos);
	
				document.documentElement.style.scrollBehavior = 'smooth';
			},300)

	}

	// Open button
	
	document.querySelectorAll('[data-open]').forEach( btn => {
		btn.addEventListener('click', ()=> {
			const target = btn.getAttribute('data-open');
			openModal(target);
		});
	});

	// Close button

	document.querySelectorAll('[data-close]').forEach( btn => {
		btn.addEventListener('click', ()=> {
			closeModal();
		});
	}); 

	// Close Esc key
	document.addEventListener('keydown', (e)=> {
		if(e.key === "Escape") {
				closeModal();
		}
	})
}


// Scroll bar width

function getScrollbarWidth() {
	const rule = document.createElement("div");
	rule.id = 'measuring-rule';
	rule.style.position = 'absolute';
	rule.style.overflowY = 'scroll';
	document.body.append(rule);
	document.body.style.setProperty(
    '--scrollbarwidth', rule.offsetWidth - rule.clientWidth + 'px'
  );
	rule.remove();
}


// Window Height

function getWindowHeight() {
	document.body.style.setProperty('--windowheight', window.innerHeight + "px" );
}


// Tabs

function tabs() {

	function activeContent ( id, index) {
		const tabContent = document.querySelector(`[data-tabs-content=${id}]`);
		for ( const content of tabContent.children ) {
			if ( Array.from(tabContent.children).indexOf(content) === index ) {
				content.style.display = 'grid';
				content.style.animationName = 'hello-content';
				if ( content.dataset.flickity ) {
					const flkty = new Flickity(content);
					flkty.resize();
				}
				const contentGaleries = content.querySelectorAll(`.c-carousel`);
				if ( contentGaleries.length > 0 ) {
					for ( const gallery of contentGaleries ) {
						const flkty = new Flickity(gallery);
						flkty.resize();
					}
				}
			} else {
				content.style.display = 'none';
				content.style.animationName = '';
			}
		}
	}

	const buttonGroups = document.querySelectorAll('[data-tabs]');
	for ( const buttonGroup of buttonGroups ) {
		const id = buttonGroup.dataset.tabs;
		for ( const button of buttonGroup.children) {
			button.addEventListener("click", () => {
				for ( const anyButton of buttonGroup.children ) {
					anyButton.classList.remove('is-active');
				}
				button.classList.add('is-active');
				const index = Array.from(buttonGroup.children).indexOf(button);
				activeContent( id, index );
			});
		}
	}
}

function tooltip() {
	document.querySelector('[data-tooltip]').addEventListener('click', function(e){
		e.target.classList.toggle('is-active');
	})

	window.addEventListener('click', function(e){
		const tooltipActive = document.querySelector('[data-tooltip].is-active');
		if ( tooltipActive && !tooltipActive.parentElement.contains(e.target)) {
			tooltipActive.classList.remove('is-active');
		}
	});

}


// ----------------------------------------------------------- READY

window.addEventListener("DOMContentLoaded", () => {
	getWindowHeight();
	getScrollbarWidth();
	modal();
	tabs();

	tooltip();
});

window.addEventListener("resize", () => {
	// getWindowHeight();
	getScrollbarWidth();
});

